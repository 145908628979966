<template>
    <div
        :class="{ active: show }"
        class="nibnut-help-button popover"
    >
        <default-button
            flavor="link"
            :size="size"
            :color="color"
            @click.prevent="toggle"
        >
            <help-circle-icon /><slot name="label"></slot>
        </default-button>
        <div
            v-if="show"
            class="popover-container"
        >
            <div class="card">
                <div class="card-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultButton from "./DefaultButton"
import HelpCircleIcon from "@/nibnut/icons/HelpCircleIcon"

export default {
    name: "HelpButton",
    components: {
        DefaultButton,
        HelpCircleIcon
    },
    methods: {
        auto_close (event) {
            if(!!event && !!event.target && !this.$el.contains(event.target)) {
                this.toggle()
            }
        },
        toggle (event) {
            if(!!event && !!event.target) event.target.closest("button").blur()
            if(this.show) {
                this.show = false
                document.removeEventListener("click", this.auto_close)
            } else {
                document.addEventListener("click", this.auto_close)
                this.show = true
            }
        }
    },
    props: {
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(primary|secondary|regular|error|warning|success|clear|light|green)$/i),
            default: "regular"
        }
    },
    data () {
        return {
            show: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-help-button {
    display: inline-block;

    .card {
        font-family: $body-font-family;
        font-size: $font-size * 0.8;
        font-weight: normal;
        line-height: $html-line-height;
        background: rgba($dark-color, .95);
        color: $light-color;

        .card-body {
            max-height: 33vh;
            overflow: auto;
        }
    }
}
</style>
