<template>
    <input-group
        :glyph="glyph"
        glyph-position="right"
        class="data-table-search-box"
    >
        <input
            ref="field"
            :id="identifier"
            type="text"
            :name="name"
            :value="value"
            :placeholder="placeholder"
            @focus="autoselect"
            @keydown="keydown"
            class="form-input"
        />
    </input-group>
</template>

<script>
import debounce from "lodash/debounce"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import InputGroup from "@/nibnut/components/Inputs/InputGroup"
import SearchIcon from "@/nibnut/icons/SearchIcon"

export default {
    mixins: [is_nibnut_component],
    components: {
        InputGroup
    },
    methods: {
        autoselect (event) {
            event.target.select()
        },
        keydown: debounce(function (event) {
            this.$emit("input", event.target.value)
        }, 300)
    },
    computed: {
        glyph () {
            return SearchIcon
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        value: {
            type: String
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            name: "search"
        }
    }
}
</script>
