import confirms from "./Confirms"

const handles_uploads = {
    mixins: [confirms],
    beforeDestroy () {
        this.cleanup_generated_files()
    },
    methods: {
        upload_file (name, file) {
            if(this.holding_mode) {
                this.$set(this.held, name, file)
                this.upload_held(name, file)
            } else {
                this.$set(this.uploaded, name, 0)
                this.$set(this.uploading, name, 1)

                const upload_progression = event => {
                    this.$set(this.uploaded, name, parseInt(Math.round((event.loaded * 100) / event.total)))
                }
                const upload_data = this.upload_data(name, "upload", file)

                return this.$store.dispatch("FILE_ACTION", {
                    ...upload_data,
                    name,
                    action: "attach",
                    file: file,
                    progress: upload_progression
                }).then(data => {
                    this.upload_attached(name, data)
                    this.$emit("uploaded", data)
                }).catch(error => {
                    this.upload_attach_error(name, error)
                }).then(() => {
                    this.$set(this.uploading, name, 0)
                })
            }
        },
        upload_file_list (name, file_list) {
            const files = []
            Array.from(file_list).forEach(file => {
                if(this.upload_accepts(name, file.name)) files.push(file)
            })

            if(!files.length) this.$error(`<strong>${this.translate("Invalid file type")}</strong>`)
            else return this.upload_file(name, files[0])
        },
        confirm_clear_file (name) {
            if(this.holding_mode) {
                if(this.held_images[name]) {
                    URL.revokeObjectURL(this.held_images[name].src)
                    this.$delete(this.held_images, name, null)
                }
                this.$delete(this.held, name, null)
            } else {
                this.confirming_clear_file = name
                this.confirm(
                    {
                        title: this.translate("Remove uploaded file"),
                        message: this.translate("Do you really want to delete the uploaded file? Make sure you have a copy if you think you'll need it again some day."),
                        cancel: this.translate("Keep"),
                        ok: this.translate("Remove")
                    },
                    "clear-file"
                )
            }
        },
        clear_file () {
            this.clearing_file = true
            this.$store.dispatch("FILE_ACTION", {
                ...this.upload_data(this.confirming_clear_file, "clear"),
                name: this.confirming_clear_file,
                action: "detach"
            }).then(response => {
                this.upload_detached(this.confirming_clear_file, response)
            }).catch(error => {
                this.upload_detach_error(this.confirming_clear_file, error)
            }).then(() => {
                this.clearing_file = false
            })
        },
        upload_accepts (name, filename = null) {
            if(filename) return true
            return ""
        },
        upload_data () {
            return {}
        },
        upload_held (name, file) {
            // console.log("upload_held", name, file)
        },
        upload_attach_error (name, error) {
            console.error("upload_attach_error", name, error)
        },
        upload_attached (name, record) {
            // console.log("upload_attached", name, record)
        },
        upload_detach_error (name, error) {
            console.error("upload_detach_error", name, error)
        },
        upload_detached (name, response) {
            // console.log("upload_detached", name, response)
        },
        generate_held_image (name, size = { width: 840 }) {
            if(!this.held[name]) this.$delete(this.held_images, name)
            else {
                const image = document.createElement("img")
                image.src = URL.createObjectURL(this.held[name])
                if(size.width) image.width = size.width
                if(size.height) image.height = size.height
                image.onload = () => {
                    this.$set(this.held_images, name, image)
                    // URL.revokeObjectURL(image.src)
                }
            }
        },
        cleanup_generated_files () {
            Object.values(this.held_images).forEach(image => {
                URL.revokeObjectURL(image.src)
            })
        }
    },
    computed: {
        holding_mode () {
            return false
        }
    },
    data () {
        return {
            held: {},
            held_images: {},
            uploaded: {},
            uploading: {},
            confirming_clear_file: null,
            clearing_file: false
        }
    }
}

export default handles_uploads
