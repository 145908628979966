<template>
    <div>
        <div v-if="waiting" class="page-waiting">
            <loader size="lg" />
        </div>
        <div
            v-show="!waiting"
            id="content"
            :class="{ narrow }"
            class="page"
        >
            <soon v-if="is_locked" />
            <div v-else-if="status===404">
                <slot name="not-found">
                    <div class="empty">
                        <h5 class="empty-title">{{ translate("Please stay on the path!") }}</h5>
                        <p class="empty-subtitle">{{ translate("Page not found") }}</p>
                    </div>
                </slot>
            </div>
            <div v-else-if="status===401">
                <slot name="unauthorized">
                    <div class="empty">
                        <h5 class="empty-title">{{ translate("That's kind of private...") }}</h5>
                        <p class="empty-subtitle">{{ translate("Unauthorized") }}</p>
                    </div>
                </slot>
            </div>
            <div v-else-if="status===200" :id="id" class="page-content">
                <div class="d-flex align-items-center page-controls">
                    <page-back
                        :is-root="isRoot"
                        :fallback="backNavigationFallback"
                        :title="title"
                        class="flex-static"
                    />
                    <slot name="controls"></slot>
                </div>
                <slot name="title">
                    <h1>{{ title }}</h1>
                </slot>
                <slot></slot>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import ui_utilities from "@/nibnut/mixins/UiUtilities"

import PageBack from "./PageBack"
import Soon from "@/custom/components/Soon"
import Loader from "@/custom/components/Loader"

export default {
    name: "Page",
    mixins: [profile_utilities, misc_utilities, ui_utilities],
    components: {
        PageBack,
        Soon,
        Loader
    },
    mounted () {
        this.load()
    },
    watch: {
        $route: "load",
        profile_id: "delayed_page_guard"
    },
    methods: {
        load () {
            if(window.location.hash) {
                setTimeout(() => {
                    this.scroll_to_hash(window.location.hash)
                }, 250)
            }
            this.page_guard()
        },
        page_guard () {
            if(!this.profile_id && window.location.search && window.location.search.match(/(\?|&)password-reset=/)) {
                this.$emit("statused", 307) // temporary redirect
                this.login({ panel_id: "reset" })
            } else if(!this.profile_id && window.location.search && window.location.search.match(/(\?|&)invitation=/)) {
                this.$emit("statused", 307) // temporary redirect
                this.login({ panel_id: "signup" })
            } else if(this.$route.meta) {
                if(this.$route.meta.admin_route) {
                    if(!this.profile_id || !this.is_at_least_administrator) {
                        if(!this.profile_id) this.login()
                        this.$emit("statused", this.profile_id ? 401 : 307)
                    } else this.$emit("statused", 200)
                } else if(!!this.$route.meta.login_required && !this.profile_id) {
                    this.login({ panel_id: true })
                    this.$emit("statused", 307)
                } else this.additional_page_guards()
            } else this.additional_page_guards()
        },
        maybe_cancel_login () {
            if(!!this.login_request && !!this.login_request.panel_id) this.$store.dispatch("UNREQUEST_LOGIN")
        },
        additional_page_guards () {
            this.maybe_cancel_login()
            this.$emit("statused", 200)
        },
        delayed_page_guard () {
            setTimeout(() => {
                this.page_guard()
            }, 250)
        },
        login (data = {}) {
            this.$store.dispatch("REQUEST_LOGIN", data)
        },
        scroll_to_hash (hash) {
            // Make sure to set the target's class to nibnut-scroll-target so the scroll is padded for the site's header's height
            this.scroll_to(document.getElementById(hash.replace(/#/, "")))
        }
    },
    computed: {
        ...mapState(["login_request", "locked"]),
        is_locked () {
            return !!this.profile_id && this.locked && !this.is_developer && !!this.$route.meta && !this.$route.meta.unlockable
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ""
        },
        waiting: {
            type: Boolean,
            default: false
        },
        narrow: {
            type: Boolean,
            default: false
        },
        status: {
            type: Number,
            default: 200
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        backNavigationFallback: {
            type: Object, // { title, href: (string | object) }
            default: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.page-waiting {
    width: 100vw;
    height: 100vh;

    & > .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.page {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: $layout-spacing;
    padding-right: $layout-spacing;
    height: 100%;
    width: 100%;

    &.narrow {
        padding-left: 15vw;
        padding-right: 15vw;
    }
    @media (max-width: $size-sm) {
        &.narrow {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
</style>
