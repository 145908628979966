<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    <span v-if="!!value">{{ value | nibnut.date(`${dateFormat} @ ${timeFormat}`) }}</span>
                    <span v-else>&nbsp;</span>
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <div class="d-flex">
            <base-date-input
                :id="id"
                :name="name"
                :value="value"
                :date-format="dateFormat"
                :position="position"
                :min="min"
                :max="max"
                :size="size"
                :calendar-size="calendarSize"
                :close-on-click="closeOnClick"
                :required="required"
                :editable="editable"
                :disabled="disabled"
                v-on="$listeners"
            >
                <template v-slot:left_addon><slot name="date-left_addon"></slot></template>
            </base-date-input>
            <div class="flex-static mx-2">
                <label class="form-label">@</label>
            </div>
            <base-time-input
                :id="`${id}-time`"
                :name="name"
                :value="value"
                :format="timeFormat"
                :required="required"
                :editable="editable"
                :disabled="disabled"
                v-on="$listeners"
            >
                <template v-slot:left_addon><slot name="time-left_addon"></slot></template>
            </base-time-input>
        </div>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import BaseDateInput from "./BaseDateInput"
import BaseTimeInput from "./BaseTimeInput"

export default {
    name: "FormDateTimeInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        BaseDateInput,
        BaseTimeInput
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        hideClearButton: {
            type: Boolean,
            default: true
        },
        dateFormat: {
            type: String,
            default: "MM/DD/YYYY"
        },
        timeFormat: {
            type: String,
            default: "hh:mm A"
        },
        position: { // use "over" for dialogs, where the popup spills out: "over" will place the calendar popup "over" the field/trigger
            validator: prop => {
                return !prop || (typeof prop === "string") || !!prop.match(/^(top|bottom|left|right|over)$/i)
            },
            default: "top"
        },
        min: { // null, string or native date
            default: null
        },
        max: { // null, string or native date
            default: null
        },
        size: {
            type: String,
            validator: prop => !!prop && prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        calendarSize: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg)$/i),
            default: ""
        },
        closeOnClick: {
            type: Boolean,
            default: true
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            required: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
