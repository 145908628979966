<template>
    <div
        :class="{ expanded: expanded || !expandable, borderless }"
        class="card card-expandable"
    >
        <div class="card-header">
            <div class="card-title h5 d-flex align-items-center" @click.prevent="maybe_toggle">
                <div class="flex-grow">
                    <slot name="title" :expanded="expanded || !expandable"></slot>
                </div>
                <div v-if="expandable" class="flex-static">
                    <slot name="controls" :expanded="expanded"></slot>
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent.stop="maybe_toggle"
                    >
                        <chevron-down-icon />
                    </default-button>
                </div>
            </div>
        </div>
        <div
            class="card-body"
        >
            <slot name="static-expandable-content"></slot>
            <div class="expandable-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import ChevronDownIcon from "@/nibnut/icons/ChevronDownIcon"

export default {
    name: "Expandable",
    components: {
        DefaultButton,
        ChevronDownIcon
    },
    methods: {
        maybe_toggle () {
            if(this.expandable) this.expanded = !this.expanded
        }
    },
    props: {
        expandable: {
            type: Boolean,
            default: true
        },
        borderless: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            expanded: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.card.card-expandable {
    .card-header {
        h5, .h5, h6, .h6 {
            margin-bottom: 0;
        }
        .btn {
            flex: 0 0 auto;

            .lucide-icon {
                transition: transform .3s ease;
            }
        }
    }
    .card-body {
        display: flex;
        flex-direction: column;

        &:last-child {
            padding-bottom: 0;
            transition: padding-bottom .3s ease;
        }

        & > .expandable-content {
            flex: 0 1 0px;
            height: auto;
            overflow: hidden;
            transition: all .3s ease;
        }
    }
    &.borderless {
        border: 0;

        .card-header,
        .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.expanded {
        .card-header {
            .btn {
                .lucide-icon {
                    transform: rotate(180deg);
                }
            }
        }
        .card-body {
            &:last-child {
                padding-bottom: $layout-spacing-lg;
            }
            & > .expandable-content {
                flex: 1 0 auto;
                overflow: visible;
            }
        }
    }
}
</style>
