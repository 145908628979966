<template>
    <div
        :id="id"
        :class="{ [`nibnut-editor-${size}`]: true }"
        class="nibnut-editor"
    >
        <div
            v-if="!!editor && !!tools.length"
            class="navbar"
        >
            <section
                class="navbar-section mb-2"
            >
                <div
                    v-for="(ids, index) in tools"
                    :key="index"
                    class="btn-group"
                >
                    <default-button
                        v-for="spec in tool_button_specs(ids)"
                        :key="spec.id"
                        :active="tool_button_active(spec)"
                        size="sm"
                        @click.prevent.stop="tool_button_cmd(spec)"
                    >
                        <component v-if="spec.active_glyph && editor.isActive(spec.id)" :is="spec.active_glyph" />
                        <component v-else-if="spec.glyph" :is="spec.glyph" />
                        <span v-else>{{ tool_button_label(spec) }}</span>
                    </default-button>
                </div>
            </section>
        </div>
        <editor-content
            ref="editor"
            :editor="editor"
        />
        <modal-dialog
            v-if="!!editor && !!has_tool('link')"
            id="link-editor"
            :show.sync="link_editing"
        >
            <form-input
                id="href"
                name="href"
                :value="link_edited.href"
                :required="true"
                @input="link_edited.href = $event"
            >
                <template v-slot:label>{{ translate("URL") }}</template>
            </form-input>

            <template v-slot:footer>
                <div class="columns">
                    <div class="column col-6 col-sm-3 text-left">
                        <default-button
                            v-if="!!editor.getAttributes('link').href"
                            color="secondary"
                            @click.prevent="link_save(true)"
                        >
                            {{ translate("Remove") }}
                        </default-button>
                    </div>
                    <div class="column col-6 col-sm-9">
                        <default-button
                            @click.prevent="link_editing = false"
                        >
                            {{ translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="primary"
                            class="ml-2"
                            @click.prevent="link_save(false)"
                        >
                            {{ translate("Save") }}
                        </default-button>
                    </div>
                </div>
            </template>
        </modal-dialog>
        <div v-if="!!editor && !!floatingTools">
            <bubble-menu
                v-if="!!bubbleTools.length"
                class="bubble-menu"
                :tippy-options="{ duration: 100 }"
                :editor="editor"
            >
                <div
                    v-for="(ids, index) in bubbleTools"
                    :key="index"
                    class="btn-group"
                >
                    <default-button
                        v-for="spec in tool_button_specs(ids)"
                        :key="spec.id"
                        :active="tool_button_active(spec)"
                        size="sm"
                        @click.prevent.stop="tool_button_cmd(spec)"
                    >
                        <component v-if="spec.active_glyph && editor.isActive(button.id)" :is="spec.active_glyph" />
                        <component v-else-if="spec.glyph" :is="spec.glyph" />
                        <span v-else>{{ tool_button_label(spec) }}</span>
                    </default-button>
                </div>
            </bubble-menu>
            <floating-menu
                v-if="!!floatingTools.length"
                class="floating-menu"
                :tippy-options="{ duration: 100 }"
                :editor="editor"
            >
                <div
                    v-for="(ids, index) in floatingTools"
                    :key="index"
                    class="btn-group"
                >
                    <default-button
                        v-for="spec in tool_button_specs(ids)"
                        :key="spec.id"
                        :active="tool_button_active(spec)"
                        size="sm"
                        @click.prevent.stop="tool_button_cmd(spec)"
                    >
                        <component v-if="spec.active_glyph && editor.isActive(button.id)" :is="spec.active_glyph" />
                        <component v-else-if="spec.glyph" :is="spec.glyph" />
                        <span v-else>{{ tool_button_label(spec) }}</span>
                    </default-button>
                </div>
            </floating-menu>
        </div>
    </div>
</template>

<script type="text/javascript">
import debounce from "lodash/debounce"
import tippy from "tippy.js"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import SuggestionsList from "@/nibnut/components/SuggestionsList"
import BoldIcon from "@/nibnut/icons/BoldIcon"
import ItalicIcon from "@/nibnut/icons/ItalicIcon"
import StrikethroughIcon from "@/nibnut/icons/StrikethroughIcon"
import AlignLeftIcon from "@/nibnut/icons/AlignLeftIcon"
import AlignCenterIcon from "@/nibnut/icons/AlignCenterIcon"
import AlignRightIcon from "@/nibnut/icons/AlignRightIcon"
import AlignJustifyIcon from "@/nibnut/icons/AlignJustifyIcon"
import ListIcon from "@/nibnut/icons/ListIcon"
import ListOrderedIcon from "@/nibnut/icons/ListOrderedIcon"
import LinkIcon from "@/nibnut/icons/LinkIcon"
import UnlinkIcon from "@/nibnut/icons/UnlinkIcon"
import QuoteIcon from "@/nibnut/icons/QuoteIcon"
import MinusIcon from "@/nibnut/icons/MinusIcon"
import UndoIcon from "@/nibnut/icons/UndoIcon"
import RedoIcon from "@/nibnut/icons/RedoIcon"

import { Editor, EditorContent, VueRenderer } from "@tiptap/vue-2"
import StarterKit from "@tiptap/starter-kit"

const button_specs = {
    h1: { id: "h1", level: 1, cmd: "toggleHeading" },
    h2: { id: "h2", level: 2, cmd: "toggleHeading" },
    h3: { id: "h3", level: 3, cmd: "toggleHeading" },
    h4: { id: "h4", level: 4, cmd: "toggleHeading" },
    h5: { id: "h5", level: 5, cmd: "toggleHeading" },
    h6: { id: "h6", level: 6, cmd: "toggleHeading" },
    bold: { id: "bold", glyph: BoldIcon, cmd: "toggleBold" },
    italic: { id: "italic", glyph: ItalicIcon, cmd: "toggleItalic" },
    strike: { id: "strike", glyph: StrikethroughIcon, cmd: "toggleStrike" },
    left: { id: "left", glyph: AlignLeftIcon, cmd: "setTextAlign" },
    center: { id: "center", glyph: AlignCenterIcon, cmd: "setTextAlign" },
    right: { id: "right", glyph: AlignRightIcon, cmd: "setTextAlign" },
    justify: { id: "justify", glyph: AlignJustifyIcon, cmd: "setTextAlign" },
    bulletList: { id: "bulletList", glyph: ListIcon, cmd: "toggleBulletList" },
    orderedList: { id: "orderedList", glyph: ListOrderedIcon, cmd: "toggleOrderedList" },
    link: { id: "link", glyph: LinkIcon, active_glyph: UnlinkIcon, method: "link_edit" },
    blockquote: { id: "blockquote", glyph: QuoteIcon, cmd: "toggleBlockquote" },
    hr: { id: "hr", glyph: MinusIcon, cmd: "setHorizontalRule" },
    undo: { id: "undo", glyph: UndoIcon, cmd: "undo" },
    redo: { id: "redo", glyph: RedoIcon, cmd: "redo" },
    mention: { id: "mention", inline_only: true }
}

const get_spec_type = (spec) => {
    if(spec.id.match(/^h\d$/)) return "header"
    if(spec.id.match(/^(left|center|right|justify)$/)) return "justification"
    return spec.id
}

export default {
    name: "BaseEditor",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        EditorContent,
        BubbleMenu: () => import("@tiptap/vue-2").then(vue2 => vue2.BubbleMenu),
        FloatingMenu: () => import("@tiptap/vue-2").then(vue2 => vue2.FloatingMenu),
        ModalDialog,
        FormInput,
        DefaultButton
    },
    mounted () {
        this.reseed()
    },
    beforeDestroy () {
        if(this.editor) {
            this.editor.destroy()
            this.editor = null
        }
    },
    watch: {
        value: "reseed"
        // dataVersion: "reseed"
    },
    methods: {
        reseed () {
            this.editor_html = this.value
            if(!this.editor) {
                const extensions = [StarterKit]
                const create_editor = () => {
                    this.editor = new Editor({
                        content: this.editor_html,
                        extensions,
                        onUpdate: () => {
                            this.debounced_emit_input(this.editor.getHTML())
                        }
                    })
                }
                const additional_extensions = []
                if(this.has_tool_of_type("justification")) {
                    additional_extensions.push(import("@tiptap/extension-text-align").then(TextAlign => {
                        const configuration = {
                            types: ["heading", "paragraph"],
                            ...(this.toolConfigurations.toolConfigurations || {})
                        }
                        extensions.push(TextAlign.default.configure(configuration))
                    }))
                }
                if(this.has_tool("link")) {
                    additional_extensions.push(import("@tiptap/extension-link").then(Link => {
                        const configuration = {
                            protocols: ["ftp", "mailto", "telto"],
                            autolink: true,
                            openOnClick: false,
                            linkOnPaste: true,
                            ...(this.toolConfigurations.link || {})
                        }
                        extensions.push(Link.default.configure(configuration))
                    }))
                }

                const suggestion_renderer = () => {
                    let component
                    let popup
                    return {
                        onStart: propsData => {
                            component = new VueRenderer(SuggestionsList, {
                                parent: this,
                                propsData
                            })
                            if(!propsData.clientRect) return

                            popup = tippy("body", {
                                getReferenceClientRect: propsData.clientRect,
                                appendTo: () => document.body,
                                content: component.element,
                                showOnCreate: true,
                                interactive: true,
                                trigger: "manual",
                                placement: "bottom-start"
                            })
                        },
                        onUpdate: propsData => {
                            component.updateProps(propsData)

                            if(!propsData.clientRect) return

                            popup[0].setProps({
                                getReferenceClientRect: propsData.clientRect
                            })
                        },
                        onKeyDown: (propsData) => {
                            if(propsData.event.key === "Escape") {
                                popup[0].hide()
                                return true
                            }

                            if(component.ref) return component.ref.onKeyDown(propsData)
                        },
                        onExit: () => {
                            popup[0].destroy()
                            component.destroy()
                        }
                    }
                }
                if(this.has_tool("mention") && this.toolConfigurations.mention) {
                    additional_extensions.push(import("@tiptap/extension-mention").then(Mention => {
                        extensions.push(Mention.default.configure({
                            HTMLAttributes: {
                                class: "chip"
                            },
                            suggestion: {
                                items: ({ query }) => {
                                    if(typeof this.toolConfigurations.mention.options === "string") {
                                        return this.$store.dispatch(
                                            "AUTOSUGGEST",
                                            {
                                                entity: this.toolConfigurations.mention.options,
                                                context: "@mention",
                                                data: { query }
                                            }
                                        ).catch(() => [])
                                    }
                                    const standardized_query = query.toLowerCase()
                                    return this.toolConfigurations.mention.options.filter(option => {
                                        return option.toLowerCase().startsWith(standardized_query)
                                    })
                                },
                                render: suggestion_renderer
                            }
                        }))
                    }))
                }
                if(this.has_tool("tokens") && this.toolConfigurations.tokens) {
                    additional_extensions.push(import("../private/TipTapToken").then(Token => {
                        extensions.push(Token.default.configure({
                            HTMLAttributes: {
                                class: "token"
                            },
                            suggestion: {
                                items: ({ query }) => {
                                    const standardized_query = query.toLowerCase()
                                    return this.toolConfigurations.tokens.options.filter(option => {
                                        return option.label.toLowerCase().startsWith(standardized_query)
                                    })
                                },
                                render: suggestion_renderer
                            }
                        }))
                    }))
                }
                /*
                if(this.has_tool("tables")) {
                    additional_extensions.push(import("@tiptap/extension-table").then(Table => {
                        extensions.push(
                            Table.configure({
                                resizable: true,
                            })
                        )
                    }))
                    additional_extensions.push(import("@tiptap/extension-table-header").then(Header => {
                        extensions.push(Header)
                    }))
                    additional_extensions.push(import("@tiptap/extension-table-row").then(Row => {
                        extensions.push(Row)
                    }))
                    additional_extensions.push(import("@tiptap/extension-table-cell").then(Cell => {
                        extensions.push(Cell)
                    }))
                }
                */
                if(additional_extensions.length) {
                    Promise.all(additional_extensions).then(create_editor)
                } else create_editor()
            } else {
                const changed = this.editor.getHTML() !== this.editor_html
                if(changed) this.editor.commands.setContent(this.editor_html, false)
            }
        },
        tool_button_specs (ids) {
            return Object.values(button_specs).filter(spec => {
                return (ids.indexOf(spec.id) >= 0) && !spec.inline_only
            })
        },
        tool_button_active (spec) {
            const spec_type = get_spec_type(spec)
            if(spec_type === "header") return this.editor.isActive("heading", { level: spec.level })
            if(spec_type === "justification") return this.editor.isActive({ textAlign: spec.id })
            return this.editor.isActive(spec.id)
        },
        tool_button_cmd (spec, event) {
            const spec_type = get_spec_type(spec)
            if(spec_type === "header") return this.run_command(spec.cmd, { level: spec.level })
            if(spec_type === "justification") return this.run_command(spec.cmd, spec.id)
            if(spec.method) return this[spec.method](spec, event)
            return this.run_command(spec.cmd)
        },
        tool_button_label (spec) {
            const spec_type = get_spec_type(spec)
            if(spec_type === "header") return `H${spec.level}`
            return spec.id
        },
        has_tool (tool_id) {
            const find = ids => {
                return ids.indexOf(tool_id) >= 0
            }
            return (
                !!this.tools.find(find) ||
                !!this.bubbleTools.find(find) ||
                !!this.floatingTools.find(find)
            )
        },
        has_tool_of_type (type) {
            const find = ids => {
                return !!ids.find(id => {
                    return !!button_specs[id] && (get_spec_type(button_specs[id]) === type)
                })
            }
            return (
                !!this.tools.find(find) ||
                !!this.bubbleTools.find(find) ||
                !!this.floatingTools.find(find)
            )
        },
        run_command (cmd, params = undefined) {
            if(this.editor) this.editor.chain().focus()[cmd](params).run()
        },
        emit_input (event) {
            this.$emit("input", event, this.name)
        },
        debounced_emit_input: debounce(function (event) {
            this.emit_input(event)
        }, 300),
        link_edit () {
            this.link_edited.href = this.editor.getAttributes("link").href || ""
            this.link_edited.target = this.editor.getAttributes("link").target || ""
            this.link_editing = true
        },
        link_save (remove = false) {
            if(remove || !this.link_edited.href) this.editor.commands.unsetLink()
            else this.editor.commands.setLink(this.link_edited)
            this.link_editing = false
        }
    },
    computed: {
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        dataVersion: {
            required: true // no longer needed?
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        },
        tools: { // for the toolbar ; null, false, [] will hide the toolbar ; { <group>: true || [button ids]}
            type: Array,
            default () {
                return [
                    ["bold", "italic", "strike"],
                    ["h3", "h4", "h5"],
                    ["orderedList", "bulletList"],
                    ["left", "center", "right", "justify"],
                    ["link", "hr"],
                    ["undo", "redo"]
                ]
                /*
                return {
                    typefaces: true, // ["bold", "italic", "strike"]
                    headers: ["h3", "h4", "h5"], // true = ["h1", "h2", "h3", "h4", "h5", "h6"]
                    lists: true, // ["orderedList","bulletList"]
                    justifications: true, // ["left","center","right","justify"]
                    misc: ["link", "hr"], // ["link", "blockquote", "hr"]
                    history: true // ["undo", "redo"]
                }
                */
            }
        },
        bubbleTools: {
            type: Array, // floating menus ; null, false, [] will disable the bubble menus
            default () {
                return [] // [ ["bold", "italic", "strike"], ["left","center","right","justify"] ]
            }
        },
        floatingTools: {
            type: Array, // floating menus ; null, false, [] will disable the floating menus
            default () {
                return [] // [ ["h3", "h4", "h5"], ["orderedList", "bulletList"], ["link", "hr"] ]
            }
        },
        toolConfigurations: {
            type: Object,
            default () {
                return {} // { <tool id or type>: <config>, ... }
            }
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            editor: null,
            editor_html: "",

            link_edited: { href: "", target: "" },
            link_editing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-editor {
    &.nibnut-editor-sm .ProseMirror {
        min-height: 1rem;
    }
    &.nibnut-editor-lg .ProseMirror {
        min-height: 10rem;
    }
    &.nibnut-editor-full {
        &, & > div, .ProseMirror {
            height: 100%;
        }
    }

    &.nibnut-editor-sm .ProseMirror,
    &.nibnut-editor-lg .ProseMirror,
    &.nibnut-editor-full .ProseMirror {
        background: $bg-color-light;
        border: $border-width solid $border-color-dark;
        border-radius: $border-radius;
        color: $body-font-color;
        font-size: $font-size;
        line-height: $line-height;
        padding: $control-padding-y $control-padding-x;

        &:focus {
            @include control-shadow();
            border-color: $primary-color;
        }
    }
    .floating-menu,
    .bubble-menu,
    .navbar .navbar-section {
        & > .btn + .btn,
        & > .btn + .btn-group,
        & > .btn-group + .btn-group,
        & > .btn-group + .btn {
            margin-left: $unit-2;
        }
    }
    .floating-menu,
    .bubble-menu {
        display: flex;
        background-color: $light-color;
        box-shadow: 0 0 0 .1rem rgba($primary-color, .2);
        padding: $unit-2;

        .btn {
            opacity: 0.6;

            &:hover,
            &.active {
                opacity: 1;
            }
        }
    }

    ul, ol {
        li > p {
            display: inline;
        }
    }

    .token {
        display: inline-flex;
        align-items: center;
        border: $border-width-lg dashed $bg-color-dark;
        font-size: 90%;
        height: $unit-6;
        line-height: $unit-4;
        margin: $unit-h;
        max-width: $control-width-sm;
        overflow: hidden;
        padding: $unit-1 $unit-2;
        text-decoration: none;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
    }
}
</style>
