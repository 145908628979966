import { mapGetters } from "vuex"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FileMusicIcon from "@/nibnut/icons/FileMusicIcon"
import FileImageIcon from "@/nibnut/icons/FileImageIcon"
import FileVolumeIcon from "@/nibnut/icons/FileVolumeIcon"

const handles_files = {
    mixins: [misc_utilities],
    components: {
        FileMusicIcon,
        FileImageIcon,
        FileVolumeIcon
    },
    methods: {
        owner_attachments_except_types (owner_type, owner_id, types) {
            return this.attachments.filter(attachment => {
                return (attachment.owner_type === owner_type) && (attachment.owner_id === owner_id) && (types.indexOf(attachment.type) < 0)
            })
        },
        owner_attachments_of_types (owner_type, owner_id, types) {
            return this.attachments.filter(attachment => {
                return (attachment.owner_type === owner_type) && (attachment.owner_id === owner_id) && (types.indexOf(attachment.type) >= 0)
            })
        },
        owner_attachments_of_category (owner_type, owner_id, category) {
            return this.attachments.filter(attachment => {
                return (attachment.owner_type === owner_type) && (attachment.owner_id === owner_id) && (attachment.category === category)
            })
        },
        owner_attachments (owner_type, owner_id) {
            return this.attachments.filter(attachment => {
                return (attachment.owner_type === owner_type) && (attachment.owner_id === owner_id)
            })
        },
        file_type_to_glyph (type) {
            if(type === this.constants("attachment_types", "TYPE_PDF")) return FileMusicIcon
            if(type === this.constants("attachment_types", "TYPE_IMAGE")) return FileImageIcon
            if(type === this.constants("attachment_types", "TYPE_AUDIO")) return FileVolumeIcon
            return "file"
        },
        file_type_to_constant (mime_type) {
            if(mime_type.match(/pdf/i)) return this.constants("attachment_types", "TYPE_PDF")
            if(mime_type.match(/(jpe?g|png)/i)) return this.constants("attachment_types", "TYPE_IMAGE")
            if(mime_type.match(/(audio|mp3)/i)) return this.constants("attachment_types", "TYPE_AUDIO")
            return null
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        attachments () {
            return this.entity_records("attachment")
        }
    }
}

export default handles_files
