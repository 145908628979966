<template>
    <div>
        <div
            tabindex="0"
            @focus="loop_to_last"
        ></div>
        <div class="modal-header">
            <dismiss-link
                v-if="dismissable"
                id="first_focusable"
                @click="dismiss"
                class="btn btn-clear float-right"
            />
            <div class="modal-title h5">
                {{ translate("Lost Password") }}
            </div>
        </div>
        <div class="modal-body">
            <base-form
                :has-required-fields="true"
                @submit.prevent.stop="send"
            >
                <p>{{ translate("Enter your email and we will send you a password reset link with instructions.") }}<p>
                <form-input
                    :id="dismissable ? 'email' : 'first_focusable'"
                    ref="first_field"
                    v-model="email"
                    name="email"
                    type="email"
                    :error="has_error('email')"
                    :required="true"
                    @input="update_email"
                >
                    <template v-slot:label v-if="!labelLess">{{ translate("Email") }}</template>
                </form-input>

                <div class="text-center mt-8">
                    <feedback-button
                        type="submit"
                        :status="feedback_status"
                        :waiting="loading"
                        :single-use="false"
                        color="primary"
                    >
                        <template v-slot:message>
                            <div
                                :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                            >
                                {{ feedback_message }}
                            </div>
                        </template>
                        {{ translate("Send me the link!") }}
                    </feedback-button>
                </div>
            </base-form>
        </div>
        <div class="modal-footer">
            <default-button
                id="last_focusable"
                flavor="link"
                size="sm"
                @click.prevent="goto('login')"
            >
                {{ translate("Eureka! I just found my password!") }}
            </default-button>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </div>
</template>

<script>
import is_panel from "./IsPanel"

import DismissLink from "@/nibnut/components/ModalDialog/DismissLink"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"

export default {
    mixins: [is_panel],
    components: {
        DismissLink,
        BaseForm,
        FormInput,
        DefaultButton,
        FeedbackButton
    },
    methods: {
        send () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("SEND_PASSWORD_RESET",
                    {
                        email: this.email
                    }
                ).then(data => {
                    this.feedback_status = true
                    this.feedback_message = data.message
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout)
        }
    }
}
</script>
