<template>
    <th
        @mouseover="hovered=true"
        @mouseout="hovered=false"
        @click="click"
    >
        {{ label }}
        <component
            v-if="sort !== false"
            :is="glyph"
            :class="{ 'active': !!sort, 'd-invisible': !sort && !hovered, 'text-gray': !sort && !hovered, 'ml-4': !compact }"
            class="print-hide"
        />
    </th>
</template>

<script type="text/javascript">
import ArrowDown01Icon from "@/nibnut/icons/ArrowDown01Icon"
import ArrowDown10Icon from "@/nibnut/icons/ArrowDown10Icon"
import ArrowDownAZIcon from "@/nibnut/icons/ArrowDownAZIcon"
import ArrowDownZAIcon from "@/nibnut/icons/ArrowDownZAIcon"
import ArrowDownNarrowWideIcon from "@/nibnut/icons/ArrowDownNarrowWideIcon"
import ArrowDownWideNarrowIcon from "@/nibnut/icons/ArrowDownWideNarrowIcon"

export default {
    components: {
        ArrowDown01Icon,
        ArrowDown10Icon,
        ArrowDownAZIcon,
        ArrowDownZAIcon,
        ArrowDownNarrowWideIcon,
        ArrowDownWideNarrowIcon
    },
    methods: {
        click () {
            this.$emit("click", this.field, (this.sort === "asc") ? "desc" : "asc")
        }
    },
    computed: {
        glyph () {
            if(this.hovered) {
                if((this.type === "amount") && (!this.sort || this.sort.match(/^desc/i))) return ArrowDownWideNarrowIcon
                if(this.type === "amount") return ArrowDownNarrowWideIcon

                if((this.type === "numeric") && (!this.sort || this.sort.match(/^desc/i))) return ArrowDown01Icon
                if(this.type === "numeric") return ArrowDown10Icon

                if(!this.sort || this.sort.match(/^desc/i)) return ArrowDownAZIcon
                return ArrowDownZAIcon
            }
            if((this.type === "amount") && this.sort && this.sort.match(/^desc/i)) return ArrowDownNarrowWideIcon
            if(this.type === "amount") return ArrowDownWideNarrowIcon

            if((this.type === "numeric") && this.sort && this.sort.match(/^desc/i)) return ArrowDown10Icon
            if(this.type === "numeric") return ArrowDown01Icon

            if(this.sort && this.sort.match(/^desc/i)) return ArrowDownZAIcon
            return ArrowDownAZIcon
        }
    },
    props: {
        field: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        sort: {
            validator: prop => !prop || prop.match(/^(asc|desc)$/),
            required: true
        },
        type: {
            type: String,
            validator: prop => !!prop && prop.match(/^(amount|numeric|alpha)$/),
            deafult: "amount"
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hovered: false
        }
    }
}
</script>
