const is_app_icon = {
    computed: {
        icon () {
            return this.icon_import
        },
        svg_props () {
            const { glyph, size, fill, ...svg_props } = this.$props
            return svg_props
        },
        standardized_size () {
            if(typeof this.size === "number") return this.size
            if(this.size === "xs") return 10
            if(this.size === "sm") return 12
            if(this.size === "lg") return 24
            if(this.size === "2x") return 32
            if(this.size === "3x") return 48
            if(this.size === "4x") return 64
            if(this.size === "5x") return 80
            return 16
        },
        standardized_fill () {
            if(this.fill === true) return this.color
            return this.fill || "transparent"
        }
    },
    props: {
        size: {
            validator: prop => {
                return !prop || (typeof prop === "number") || !!prop.match(/^(xs|sm|lg|2x|3x|4x|5x)$/i)
            },
            default: ""
        },
        color: {
            type: String,
            default: "currentColor"
        },
        strokeWidth: {
            type: Number,
            default: 1
        },
        fill: {
            type: [String, Boolean],
            default: false
        }
    },
    data () {
        return {
            icon_import: null
        }
    }
}

export default is_app_icon
