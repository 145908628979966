<template>
    <component
        :is="icon"
        :size="standardized_size"
        :fill="standardized_fill"
        v-bind="svg_props"
        :class="`lucide-icon-${size}`"
    />
</template>

<script type="text/javascript">
import is_app_icon from "./IsAppIcon"

export default {
    name: "ArrowDownZAIcon",
    mixins: [is_app_icon],
    mounted () {
        this.icon_import = () => import("lucide-vue/dist/esm/icons/arrow-down-z-a.js")
    }
}
</script>
