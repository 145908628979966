<template>
    <div class="header app-header">
        <header class="navbar">
            <section class="navbar-section">
                <default-button
                    v-if="is_at_least_administrator"
                    flavor="link"
                    @click.prevent="$emit('navigate', null)"
                >
                    <menu-icon />
                </default-button>
                <default-button
                    v-else
                    flavor="link"
                    @click.prevent="$emit('install')"
                >
                    <arrow-down-circle-icon />
                </default-button>
                <default-button
                    v-if="!!app_context && !!app_context.settings && app_context.settings.bug_reporter_active && !!profile_id"
                    flavor="link"
                    class="show-sm"
                    @click.prevent="bug_report"
                >
                    <bug-icon />
                </default-button>
            </section>
            <section class="navbar-center">
                <base-link
                    :href="{ name: 'home' }"
                    @click.native="$emit('navigate', false)"
                >
                    <app-logo />
                </base-link>
            </section>
            <section class="navbar-section">
                <default-button
                    v-if="!!app_context && !!app_context.settings && app_context.settings.bug_reporter_active && !!profile_id"
                    flavor="link"
                    class="mr-4 hide-sm"
                    @click.prevent="bug_report"
                >
                    <bug-icon size="lg" />
                </default-button>
                <base-link
                    v-if="!!profile_id"
                    href="https://media.oqe.nibnut.com/storage/songbook-oqe-2024.pdf"
                    target="_blank"
                    class="btn btn-link mr-4"
                >
                    <file-music-icon size="lg" :title="translate('Songbook')" />
                </base-link>
                <base-link
                    v-if="!is_post_event && !!profile_id && !is_locked"
                    href="https://media.oqe.nibnut.com/storage/program-oqe-2024.pdf"
                    target="_blank"
                    class="btn btn-link mr-4"
                >
                    <calendar-clock-icon size="lg" :title="translate('Day\'s Schedule')" />
                </base-link>
                <base-link
                    v-if="!!profile_id"
                    href="https://media.oqe.nibnut.com/storage/map-oqe-2024.pdf"
                    target="_blank"
                    class="btn btn-link mr-4"
                >
                    <map-pin-icon size="lg" :title="translate('Site Map')" />
                </base-link>
                <default-button
                    v-if="!!profile_id && !is_locked"
                    flavor="link"
                    :title="translate('Logout')"
                    @click.prevent="logout"
                >
                    <log-out-icon size="lg" />
                </default-button>
                <default-button
                    v-else-if="!profile_id"
                    flavor="link"
                    :title="translate('Login/Signup')"
                    @click.prevent="login"
                >
                    <log-in-icon size="lg" />
                </default-button>
                <base-link
                    :href="{ name: 'profile' }"
                    class="btn btn-link"
                >
                    <figure class="avatar avatar-sm">
                        <user-icon v-if="!profile_id||(!!profile&&!profile.avatar)" />
                        <img v-else :src="profile.avatar" :alt="profile.name">
                    </figure>
                </base-link>
            </section>
        </header>
    </div>
</template>

<script>
import { mapState } from "vuex"
import { profile_utilities } from "@/nibnut/mixins"

import { AppLogo, DefaultButton, BaseLink } from "@/nibnut/components"
import { MenuIcon, ArrowDownCircleIcon, BugIcon, CalendarClockIcon, FileMusicIcon, MapPinIcon, LogInIcon, LogOutIcon, UserIcon } from "@/nibnut/icons"

export default {
    mixins: [profile_utilities],
    components: {
        AppLogo,
        DefaultButton,
        BaseLink,
        MenuIcon,
        ArrowDownCircleIcon,
        BugIcon,
        CalendarClockIcon,
        FileMusicIcon,
        MapPinIcon,
        LogInIcon,
        LogOutIcon,
        UserIcon
    },
    methods: {
        bug_report () {
            this.$emit("bug-report")
        },
        login () {
            this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
        },
        logout () {
            this.$store.dispatch("LOGOUT")
        }
    },
    computed: {
        ...mapState(["is_post_event"]),
        is_locked () {
            return !!this.profile_id && this.locked && !this.is_developer && !!this.$route.meta && !this.$route.meta.unlockable
        }
    },
    props: {
        isPublicPage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.header {
    .navbar-section:first-child {
        padding-left: 0.3rem;
    }
    .btn {
        border: 0;

        i {
            font-size: 1rem;
        }
    }
    @media print {
        display: none;
    }
}
</style>
