<template>
    <div class="columns coming-soon">
        <div class="column col-6 col-sm-12">
            <div class="card empty">
                <div class="card-header">
                    <div class="card-title h4">{{ translate("OQE") }} {{ starts_at | nibnut.date("YYYY") }}</div>
                </div>
                <div class="card-body">
                    <div class="empty-icon text-center">
                        <music-4-icon
                            size="5x"
                            class="square xyz-in"
                            xyz="duration-7 ease-in-out-back skew-left-1 direction-alternate iterate-infinite"
                        />
                    </div>
                    <h5 v-if="time" class="empty-title">{{ translate("in") }} {{ time }} {{ translate("hours") }}</h5>
                    <h5 v-else-if="time === ''" class="empty-title">{{ translate("Any moment now...") }}</h5>
                    <p class="empty-subtitle text-italic">{{ tagline }}</p>
                    <div class="col-3 col-sm-6 col-mx-auto text-left">
                        <ul>
                            <li
                                v-for="(count, part_id) in constants('registration_stats').counts"
                                :key="part_id"
                                :class="{ [`text-${color_for_count(count)}`]: true }"
                                class="d-flex"
                            >
                                <span class="flex-variable">
                                    <default-button
                                        v-if="!!tooltip_for_part_id_and_count(part_id, count)"
                                        flavor="link"
                                        :color="color_for_count(count)"
                                        size="sm"
                                        class="tooltip"
                                        :data-tooltip="tooltip_for_part_id_and_count(part_id, count)"
                                    >
                                        <alert-octagon-icon />
                                    </default-button>
                                    {{ part_by_part_id(part_id).name }}
                                </span>
                                <span class="flex-static">
                                    {{ count | nibnut.number("0,0") }}&nbsp;/&nbsp;{{ constants("registration_stats").max | nibnut.number("0,0") }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="column col-6 col-sm-12">
            <div class="card songbook">
                <div class="card-header">
                    <div class="card-title h4">{{ translate("Song Book") }}</div>
                </div>
                <div class="card-body">
                    <ul class="menu">
                        <li
                            v-for="song in songs"
                            :key="song.id"
                            class="menu-item"
                        >
                            <div class="mb-2">
                                <span class="text-bold">{{ song.title }}</span>
                                <span
                                    v-if="song.purpose === constants('song_purposes', 'PURPOSE_CHORUS').id"
                                    class="chip ml-2"
                                >
                                    {{ constant_value("song_purposes", song.purpose).name }}
                                </span>
                            </div>
                            <base-link
                                v-for="file in song_sheet_music(song)"
                                :key="file.id"
                                :href="file.url"
                                target="_blank"
                                :title="file.name"
                            >
                                <file-music-icon /> {{ translate("Sheet Music") }}
                            </base-link>
                            <div v-if="!!song_learning_tracks(song).length" class="text-small columns">
                                <div class="column flex-static">
                                    <label class="form-label label-sm">
                                        <file-volume-icon /> {{ translate("Tracks") }}:
                                    </label>
                                </div>
                                <div class="column songbook-tracks">
                                    <base-link
                                        v-for="file in song_learning_tracks(song)"
                                        :key="file.id"
                                        :href="file.url"
                                        target="_blank"
                                        :title="file.name"
                                        class="btn btn-link btn-sm"
                                    >
                                        {{ learning_track_short_name(song, file) }}
                                    </base-link>
                                    <br v-if="song_learning_tracks(song, true).length" />
                                    <base-link
                                        v-for="file in song_learning_tracks(song, true)"
                                        :key="file.id"
                                        :href="file.url"
                                        target="_blank"
                                        :title="file.name"
                                        class="btn btn-link btn-sm"
                                    >
                                        {{ learning_track_short_name(song, file) }}
                                    </base-link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"

import Music4Icon from "@/nibnut/icons/Music4Icon"
import AlertOctagonIcon from "@/nibnut/icons/AlertOctagonIcon"
import FileMusicIcon from "@/nibnut/icons/FileMusicIcon"
import FileVolumeIcon from "@/nibnut/icons/FileVolumeIcon"

export default {
    name: "Soon",
    mixins: [profile_utilities, misc_utilities],
    components: {
        DefaultButton,
        BaseLink,
        Music4Icon,
        AlertOctagonIcon,
        FileMusicIcon,
        FileVolumeIcon
    },
    mounted () {
        this.$store.dispatch(
            "FETCH_RECORDS",
            {
                entity: "song",
                query: { relation_ids: ["attachment"] }
            }
        ).catch(error => {
            console.error(error.message)
        })
        this.refresh_time()
    },
    methods: {
        refresh_time () {
            const SECONDS_PER_MINUTE = 60
            const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE
            const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR

            const starts_at = this.starts_at
            const now = this.$dayjs()
            let seconds = starts_at.diff(now, "seconds")
            if(seconds <= 0) this.time = ""
            else {
                const days = Math.floor(seconds / SECONDS_PER_DAY)
                const hours = Math.floor((seconds % SECONDS_PER_DAY) / SECONDS_PER_HOUR)
                const minutes = Math.floor((seconds % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE)
                seconds = Math.floor(seconds % SECONDS_PER_MINUTE)

                if(days > 30) this.tagline = this.translate("Practice makes perfect!")
                else if(days > 7) this.tagline = this.translate("Hope you know your songs!!!")
                else if(days) this.tagline = this.translate("Shh! Rest your instrument: you're gonna need it!")
                else this.tagline = this.translate("Get them vocal chords runnin'!")

                this.time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
                if(days) this.time = `${days.toString()} days ${this.time}`
                setTimeout(this.refresh_time, 500)
            }
        },
        part_by_part_id (part_id) {
            return this.constant_value("parts", parseInt(part_id.replace(/[^0-9]+/ig, "")))
        },
        color_for_count (count) {
            const max_count = Math.max(...Object.values(this.constants("registration_stats").counts))
            if(!max_count) return ""
            const delta = Math.round(((max_count - count) / max_count) * 100)
            if(delta >= 20) return "error"
            if(delta) return "warning"
            return ""
        },
        tooltip_for_part_id_and_count (part_id, count) {
            const color = this.color_for_count(count)
            if(color) {
                const part = this.part_by_part_id(part_id)
                if(color === "error") return `We are VERY low on ${part.name}s! Please help us find more!`
                return `We are low on ${part.name}s! Know anyone?`
            }
            return ""
        },
        song_files (song, type = null) {
            const type_id = type ? this.constants("attachment_types", type) : 0
            return this.entity_records("attachment").filter(attachment => {
                return (attachment.owner_type === "App\\Song") && (attachment.owner_id === song.id) && (!type_id || (attachment.type === type_id))
            })
        },
        song_sheet_music (song) {
            return orderBy(this.song_files(song, "TYPE_PDF"), "name", "asc")
        },
        song_learning_tracks (song, extras = false) {
            const parts = [...this.constant_options("parts")]
            const index = parts.findIndex(part => part.id === this.profile.part)
            if(index >= 0) {
                const removed = parts.splice(index, 1)
                parts.unshift(removed[0])
            }
            const files = orderBy(
                this.song_files(song, "TYPE_AUDIO"),
                attachment => {
                    const part_index = parts.findIndex(part => {
                        return (attachment.name.toLowerCase().indexOf(part.name.toLowerCase()) >= 0)
                    })
                    return `${part_index}_${attachment.name}`
                },
                "asc"
            )
            if(files.length > 5) files.splice(extras ? 0 : 4, extras ? 4 : Math.max(0, files.length - 4))
            else if(extras) return []
            return files
        },
        learning_track_short_name (song, learning_track) {
            return learning_track.name.replace(song.title, "").trim()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        starts_at () {
            return this.$dayjs(this.setting("starts_at"))
        },
        songs () {
            return orderBy(this.entity_records("song"), "title", "asc")
        }
    },
    data () {
        return {
            time: null,
            tagline: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.coming-soon {
    height: 100%;

    & > .column {
        max-height: 100%;
    }

    .card {
        height: 100%;

        .card-title {
            text-align: center;
        }

        &.empty {
            padding: 0;
            border: 0;

            & > .card-body {
                display: flex;
                flex-direction: column;
                justify-content: center;

                ul > li {
                    position: relative;

                    .btn.btn-sm {
                        position: absolute;
                        left: -30px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }
                }
            }
        }
        &.songbook {
            & > .card-body {
                overflow-y: auto;
                ul.menu {
                    text-align: left;
                    box-shadow: none;
                    min-width: 0;
                    padding: 0;
                    transform: none;

                    & > li.menu-item {
                        & > a {
                            text-decoration: underline;
                            &:focus {
                                background: inherit;
                                color: inherit;
                            }
                            @include hover-supported {
                                background: inherit;
                                color: inherit;
                            }
                        }
                        .songbook-tracks {
                            flex-wrap: wrap;
                        }

                        & + li.menu-item {
                            margin-top: $unit-2;
                            padding-top: $unit-2;
                            border-top: $border-width solid $border-color-light;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $size-sm) {
        & > .column {
            max-height: none;
        }
        .column + .column {
            margin-top: $layout-spacing * 2;
        }

        .card {
            &.songbook {
                & > .card-body {
                    overflow-y: visible;
                }
            }
        }
    }
}
</style>
