<template>
    <form-input
        v-if="!!country && !country_regions[country]"
        v-bind="input_props"
        @input="$emit('input', $event, name)"
    >
        <template slot="label">
            <small>{{ province_label }}</small>
        </template>
    </form-input>
    <form-select
        v-else
        :value="value"
        :dataSource="country_regions[country]"
        empty-value=""
        :empty-label="pick_a_province_label"
        :required="true"
        v-bind="select_props"
        @input="$emit('input', $event, name)"
    >
        <template slot="label">
            <small>{{ province_label }}</small>
        </template>
    </form-select>
</template>

<script type="text/javascript">
import country_regions from "./country_regions"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"

export default {
    components: {
        FormInput,
        FormSelect
    },
    methods: {
        change_to (value) {
            this.$emit("input", value, this.name)
        }
    },
    computed: {
        input_props () {
            const { country, ...input_props } = this.$props
            return input_props
        },
        select_props () {
            const { country, value, ...select_props } = this.$props
            return select_props
        },
        country_regions () {
            return country_regions
        },
        province_label () {
            if(this.country) {
                if(this.country === "US") return this.translate("State")
                if(this.country === "CA") return this.translate("Province")
            }
            return this.translate("State / Province")
        },
        pick_a_province_label () {
            if(this.country) {
                if(this.country === "US") return `(${this.translate("Pick a state")}...)`
            }
            return `(${this.translate("Pick a province")}...)`
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            type: String,
            default: ""
        },
        country: {
            type: String,
            default: "CA"
        }
    }
}
</script>
