<template>
    <div v-if="files.length" class="files-list">
        <span
            v-for="file in files"
            :key="file.id || file.url"
            class="chip"
        >
            <figure class="avatar avatar-sm">
                <component :is="file_type_to_glyph(file.type)" />
            </figure>
            <base-link
                :href="file.url"
                :title="file.name"
                :download="(file.type === constants('attachment_types', 'TYPE_PDF').id) ? undefined : true"
                :target="(file.type === constants('attachment_types', 'TYPE_PDF').id) ? '_blank' : '_self'"
            >
                {{ file.name }}
            </base-link>
            <a
                v-if="editable"
                href="#"
                class="btn btn-clear"
                :aria-label="translate('Delete')"
                role="button"
                @click.prevent.stop="$emit('delete', file)"
            ></a>
        </span>
    </div>
</template>

<script>
import handles_files from "@/custom/mixins/HandlesFiles"

import BaseLink from "@/nibnut/components/Links/BaseLink"

export default {
    name: "FilesList",
    mixins: [handles_files],
    components: {
        BaseLink
    },
    props: {
        files: {
            type: Array,
            default () {
                return []
            }
        },
        editable: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.files-list {
    .chip {
        .avatar {
            text-align: center;
            .lucide-icon {
                font-size: 1.4em;
                line-height: 1.4em;
                vertical-align: 0;
            }
        }
    }
}
</style>
