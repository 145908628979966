<template>
    <form-group
        v-bind="form_group_props"
        class="password-editor"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint>
            <ul>
                <li
                    v-for="criterion in criteria"
                    :key="criterion.id"
                    :class="{ 'text-success': criterion_valid(criterion), 'text-error': !!error&&!criterion_valid(criterion) }"
                >
                    <component :is="criterion_glyph(criterion)" />
                    {{ criterion.label }}
                </li>
            </ul>
        </template>
        <password-field
            v-bind="password_field_props"
            :error="standardized_error"
            @keyup="changed"
            v-on="$listeners"
        >
            <template v-slot:right_addon="{ toggle }"><slot name="right_addon" :toggle="toggle"></slot></template>
        </password-field>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FormGroup from "./FormGroup"
import PasswordField from "./PasswordField"
import CheckIcon from "@/nibnut/icons/CheckIcon"
import XIcon from "@/nibnut/icons/XIcon"

export default {
    name: "PasswordEditor",
    mixins: [is_nibnut_component, misc_utilities],
    components: {
        FormGroup,
        PasswordField,
        CheckIcon,
        XIcon
    },
    methods: {
        criterion_valid (criterion) {
            if(criterion && criterion.condition) return criterion.condition.test(this.value)
            return false
        },
        criterion_glyph (criterion) {
            if(this.criterion_valid(criterion)) return CheckIcon
            return XIcon
        },
        changed (event) {
            this.$emit("input", event.target.value)
        }
    },
    computed: {
        standardized_error () {
            if(this.error && (this.error !== "__invalid__")) return this.error
            return ""
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.standardized_error,
                waiting: this.saving
            }
        },
        password_field_props () {
            const { error, ...field_props } = this.$props
            return field_props
        },
        criteria () {
            const length = this.setting("password_length")
            return [
                { id: "length", label: this.translate("At least {length} characters", { length }), condition: new RegExp(`.{${length},}`) },
                { id: "lowercase", label: this.translate("One lowercase letter"), condition: /[a-z]/ },
                { id: "upercase", label: this.translate("One uppercase letter"), condition: /[A-Z]/ },
                { id: "number", label: this.translate("One number"), condition: /[0-9]/ },
                { id: "special", label: this.translate("One special character"), condition: /[@!#$%^&*()+=\-[\]\\';,./{}|":<>?~]/ }
            ]
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: "" // return "__invalid__" if it's a validation error, enaything else to display the error (i.e. "Password is required", etc...)
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.password-editor {
    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        & > li {
            padding: $control-padding-y-sm $control-padding-x-sm;
        }
    }
}
</style>
